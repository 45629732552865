@use 'sass:color';
@use './bright.scss';
@use './dark.scss';

@font-face {
    font-family: 'Neon Sans';
    src: url('../fonts/NeonSans.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DotGothic16';
    src: url('../fonts/DotGothic16-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

$primary: #ff1493;
$secondary: #00ffff;
$accent: #a300ef;
$neutral: #100114;
$light: #f2ffff;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    transition: all 0.15s ease-in-out;
    ::-webkit-scrollbar {
        @apply w-3;
        &:vertical {
            margin-right: 1rem;
        }
    }

    ::-webkit-scrollbar-track {
        border: 1px solid black;
        &:vertical {
            border-right: none;
        }
        &:horizontal {
            border-bottom: none;
        }
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-primary;
        border: 1px solid black;
        border-right: none;
    }
    ::-webkit-scrollbar-corner {
        border: 1px solid $neutral;
        border-right: 0;
        border-bottom: 0;
    }
    ::-webkit-scrollbar-button {
        @apply block h-3 w-3;

        &:start:increment,
        &:end:decrement {
            display: none;
        }
        &:vertical {
            &:increment,
            &:decrement {
                border-left: 1px solid black;
            }
        }
        &:horizontal {
            &:increment,
            &:decrement {
                border-top: 1px solid black;
            }
        }
    }
}

body {
    font-family: 'DotGothic16';
    font-size: 16px;
    min-height: 100vh;
    min-width: 100%;
    position: relative;
    overflow: hidden;
}

#root {
    min-width: 100%;
    height: 100vh;
    overflow: hidden;
}

#container {
    height: 100vh;
    width: 100%;
    overflow: auto;
    &.pageContain {
        height: calc(100vh - 53px);
        margin-top: 53px;
    }
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &.cta {
        @apply btn mx-auto text-2xl mb-8 px-10 py-4 w-fit h-fit btn-primary uppercase border border-neutral;
        color: $light !important;
        &:hover {
            @apply btn-accent border border-neutral;
        }
    }
}

header {
    @apply px-5 py-3 border-b backdrop-blur-sm w-full fixed;
    z-index: 999999;
    .mobile {
        @apply hidden;
    }
    .headerWrap {
        @apply w-full flex justify-between items-center;
    }
    nav {
        @apply w-full text-lg flex gap-9 items-center;
    }
}

.switch {
    @apply relative flex justify-center items-center;
    height: 22px;
    cursor: pointer;
    .switchHandle {
        @apply absolute;
        height: 21px;
        width: 21px;
        transition: all 0.25s ease-in-out;
    }
    .switchBack {
        height: 10px;
        width: 35px;
    }
}

.home {
    .logoBlock {
        @apply absolute px-2 py-5;
        min-height: 150px;
        width: 450px;
        z-index: 150;
        // top: calc(50vh - 100px);
        top: calc(50% - 85px + 2rem);
        // left: calc(50% - 225px);
        // left: 200px;
        left: calc(55% - 450px);
        .logoInner {
            @apply flex flex-col justify-center items-center h-full p-3;
        }
        h1 {
            @apply text-5xl;
        }
        h2 {
            @apply text-2xl uppercase mb-4;
        }
        hr {
            @apply my-2 mx-0 w-11/12;
        }
    }
    .draggable {
        @apply absolute;
    }
    .portfolio {
        left: 3rem;
        top: 2rem;
        width: calc(55% - 4rem);
        height: calc(50% - 85px);
        z-index: 3;
        .innerWrap {
            // @apply grid-cols-4;
            @apply flex flex-wrap justify-center gap-3 items-center;
        }
        .item {
            @apply flex flex-col items-center p-2 text-center;
            height: 150px;
            .icon {
                @apply mb-3 w-fit;
                height: 90px;
                max-width: 100%;
                margin-bottom: 5px;
                img {
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
    .blog {
        @apply h-1/2;
        top: 4rem;
        z-index: 1;
        right: 5%;
        width: 35%;
        .menuBar {
            p {
                cursor: pointer;
                &::first-letter {
                    font-weight: 900;
                    text-decoration: underline;
                }
            }
        }
        .innerWrap {
            @apply p-0;

            .post {
                @apply border-y p-2;
                &:first-of-type {
                    @apply border-t-0;
                }
            }
            h2 {
                @apply text-xl;
            }
            .date {
                @apply text-sm opacity-50 py-1 px-5;
            }
            .readMore {
                @apply ml-5;
            }
            &.tags {
                align-items: start;
            }
        }
        .tags,
        .categories {
            @apply p-3 flex flex-col gap-3;
            .item {
                @apply flex items-center gap-2;
            }
        }
    }
    .about {
        width: 55%;
        height: 32%;
        z-index: 3;
        bottom: 3rem;
        left: 43%;
        .innerWrap {
            overflow: visible;
            height: calc(100% - 35px);
        }
        .techWrap {
            @apply flex flex-wrap gap-3 items-center justify-center;
            // @apply grid grid-cols-8 grid-rows-2 gap-3;
            padding: 1rem 5rem;
            height: 100%;
            .item {
                // @apply h-full w-full flex justify-center items-center;
                height: 30%;
                position: relative;
            }
            img {
                max-height: 100%;
                width: auto;
            }
            .hoverBox {
                @apply border p-3 text-center;
                position: absolute;
                z-index: 99;
                top: 80%;
                left: 50%;
                transform: translateX(-50%);
                &::after {
                    @apply absolute h-4 w-4 border-t border-l;
                    content: '';
                    top: -9px;
                    left: 50%;
                    transform: translateX(-50%) rotate(45deg);
                }
            }
        }
    }
    .desktopIcons {
        @apply w-2/5 grid grid-cols-4 grid-rows-5 absolute bottom-5;
        height: 500px;
        .icon,
        .icon a {
            @apply flex flex-col justify-center items-center gap-2;
            cursor: pointer;
            svg {
                height: 2.5rem;
                width: auto;
            }
        }
        .socialIcon {
            grid-row: 3/4;
            &:nth-of-type(3) {
                grid-column: 3/4;
            }
            &:nth-of-type(4) {
                grid-column: 4/5;
            }
        }
        .gitHub {
            grid-row: 2/3;
            grid-column: 2/3;
        }
        .menuIcon {
            grid-row: 5/6;
        }
        .clockIcon {
            grid-row: 4/5;
            grid-column: 1/2;
        }
        .calcIcon {
            grid-row: 4/5;
            grid-column: 2/3;
        }
        .contactIcon {
            grid-row: 4/5;
            grid-column: 4/5;
        }
    }
    .switch {
        @apply absolute top-2.5 right-2.5;
        height: 44px;
        .switchHandle {
            height: 40px;
            width: 40px;
        }
        .switchBack {
            height: 20px;
            width: 70px;
        }
    }
}

.page {
    @apply py-5 px-5;
    h1 {
        @apply text-4xl w-2/3 px-5 pt-3 pb-4 ml-8 border mb-16;
    }
    .window {
        @apply w-9/12 -mt-4;
        .innerWrap {
            @apply p-0;
        }
        h2 {
            @apply text-lg uppercase px-8 py-2 border-b;
        }
        .subHead {
            @apply flex justify-between items-center py-2 px-3 text-sm opacity-50;
        }

        .featImg {
            @apply overflow-hidden object-cover border-b;
            max-height: 200px;
        }
        .body {
            .text {
                @apply p-5;
            }
        }
        .categories {
            @apply flex gap-3;
        }
        .postFoot {
            @apply p-2 pb-3 border-t;
            .tags {
                @apply flex gap-3;
                min-height: 24px;
            }
        }
    }
    .posts {
        @apply mx-auto;
        width: 1048px;
        .window {
            .menuBar {
                @apply flex justify-between items-center w-full flex-wrap;
            }
            &:nth-of-type(2n) {
                @apply ml-auto;
            }
        }
    }
}

.post {
    .menuBar {
        @apply justify-between;
    }
    .tags,
    .categories {
        @apply flex gap-3;
        p {
            @apply m-0;
        }
    }
    p {
        @apply mb-5;
    }

    figure {
        @apply border mb-8 w-fit mx-auto;
        max-width: 80%;
        &.alignright {
            float: right;
            // margin-right: 0.5rem;
            margin-right: -5%;
            max-width: 50%;
            margin-left: 1.5rem;
        }
        &.alignleft {
            float: left;
            margin-left: -5%;
            max-width: 50%;
            margin-right: 2rem;
        }
        &.alignfull,
        &.alignwide {
            width: calc(100% + 2rem);
            z-index: 10;
            margin-left: -1rem;
            max-width: unset;
            img {
                width: 100%;
                height: auto;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.alignwide {
            width: calc(100% + 6rem);
            margin-left: -3rem;
        }
    }
    .window {
        @apply mx-auto w-4/5;
        max-width: 1048px;
        overflow-x: visible;
        .inner {
            overflow: visible !important;
        }
        .innerWrap {
            @apply p-4;
            overflow: visible;
        }
        .featImg {
            @apply border-t-0 -mt-4 -ml-4 h-fit mb-5;
            width: calc(100% + 2rem);
            max-height: unset;
        }
        .postFoot {
            @apply pb-3 px-5 text-sm;
            p {
                @apply opacity-50 m-0;
            }
        }
    }
}

.projects {
    @apply flex flex-col items-center mx-auto;
    width: 1048px;
    .window {
        width: fit-content;
        max-width: 50%;
        .menuBar {
            @apply flex-wrap gap-x-3 gap-y-2;
            .lang {
                &::first-letter {
                    font-weight: 900;
                    text-decoration: underline;
                }
            }
        }
        .inner {
            @apply w-fit h-fit;
            display: block;
            overflow: hidden;
            max-width: calc(100% - 20px);
            a {
                @apply h-full w-fit;
            }
            img {
                max-width: 100%;
                height: auto;
                width: auto;
            }
            .innerWrap {
                max-height: unset;
                width: fit-content;
                // height: 400px;
                height: fit-content;
                overflow: hidden;
            }
        }
        &:nth-of-type(2n + 1) {
            // transform: translateX(-25%);
            margin-right: 45%;
        }
        &:nth-of-type(2n) {
            // transform: translateX(50%);
            margin-left: 45%;
        }
    }
}

.project {
    .window.main {
        overflow: visible;
        .inner,
        .innerWrap {
            overflow: visible;
        }
        .demo {
            h1 {
                @apply mt-3 mx-auto text-center;
            }
        }
        .body {
            @apply flex gap-3;
        }
        .content {
            @apply grow;
            max-width: 600px;
        }
        .tech {
            @apply w-fit uppercase mb-5;
            margin-right: -3rem;
            min-width: 250px;
            ul {
                @apply p-3;
                li {
                    @apply flex gap-2 items-center;
                    &::before {
                        @apply h-3 w-3 border block;
                        margin-top: 2px;
                        border-radius: 100%;
                        content: '';
                    }
                }
            }
            .repo {
                @apply p-3 border-t mt-auto;
            }
        }
        .featImg {
            @apply flex justify-center items-center mb-0;
            img {
                width: 100%;
                height: auto;
                cursor: pointer;
            }
        }
        .window.gallery {
            @apply mb-8 mt-0;
            margin-top: -2px;
            width: calc(100% + 5rem);
            margin-left: -2.5rem;
            max-width: unset;
            .innerWrap {
                @apply flex gap-3 justify-center items-center flex-wrap;
            }
            img {
                @apply border;
                height: 100px;
                width: auto;
                cursor: pointer;
            }
        }
    }
}

.window {
    @apply w-full h-full border border-neutral relative z-10;
    .cornerWrap {
        @apply absolute top-0 left-0 h-full w-full overflow-hidden;
    }
    &.hidden {
        display: none;
    }
    .menuBar {
        @apply flex gap-4 py-2 px-4 uppercase;
        letter-spacing: 0.1rem;
        a {
            &::first-letter {
                font-weight: 900;
                text-decoration: underline;
            }
        }
    }
    .inner {
        @apply m-2.5 border border-neutral relative opacity-100 flex flex-col;
        height: calc(100% - 22px);
        min-height: 200px;
    }

    .innerWrap {
        @apply p-3 grow pb-5;
        overflow-y: auto;
        max-height: 100%;
    }

    .bar {
        @apply w-full flex items-center justify-between;
        flex-shrink: 0;
        p,
        a {
            @apply my-0 mx-auto font-bold uppercase p-1 block;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        .title {
            text-wrap: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100% - (33px * 2));
        }
        .barButton,
        .arrowBtn {
            @apply flex justify-center items-center mx-0;
            height: 33px;
            width: 33px;
            .dash {
                height: 6px;
                width: 15px;
                pointer-events: none;
            }
            .arrow {
                height: 10px;
                width: 10px;
                background-image: linear-gradient(
                    45deg,
                    $primary 50%,
                    transparent 50%
                );
            }

            .right {
                transform: translateX(-3px) rotate(225deg);
            }
            .down {
                transform: translateY(-3px) rotate(-45deg);
            }
            .up {
                transform: translateY(3px) rotate(135deg);
            }
        }
        .arrowBtn {
            &:hover {
                .arrow {
                    background-image: linear-gradient(
                        45deg,
                        $accent 50%,
                        transparent 50%
                    );
                }
            }
            &:active {
                .arrow {
                    background-image: linear-gradient(
                        45deg,
                        $secondary 50%,
                        transparent 50%
                    );
                }
            }
        }
    }
    .corner {
        @apply absolute opacity-100;
        width: 38px;
        height: 41px;
    }
    .tags {
        @apply flex gap-3 items-center;
        a.tag {
            @apply btn;
        }
    }
}

.about {
    .portrait {
        @apply mb-6 mt-3 -ml-12;
        max-width: 400px;
        float: left;
        margin-right: 2rem;
        .inner {
            overflow: hidden;
        }
    }
    .main {
        width: 1024px;
        margin: 0 auto;
        .inner,
        .innerWrap {
            overflow: visible;
        }
        .innerWrap {
            @apply p-4;
            p {
                @apply mb-3;
            }
        }
    }
}

.contact {
    @apply h-full w-full flex justify-center items-center;
    .main {
        @apply h-fit;
        form {
            @apply p-5 flex flex-col gap-3;
            label {
                @apply input input-bordered flex items-center gap-2 w-4/5 mx-auto bg-transparent;
                height: unset;
                input,
                textarea {
                    @apply m-3;
                }
                &.message {
                    @apply h-fit pr-0 items-start;
                    span {
                        @apply mt-3;
                    }
                    textarea {
                        @apply grow bg-transparent m-0 ml-3;
                        min-height: 150px;
                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    .success {
        h3 {
            @apply text-center p-3 border-b text-2xl;
        }
        p {
            @apply text-center p-3 mt-3;
        }
        button {
            @apply btn btn-outline my-5 mx-auto block;
        }
    }
}

.bigImg {
    @apply fixed top-0 left-0 h-screen w-screen flex justify-center items-center backdrop-blur-sm;
    z-index: 999999;
    img {
        max-width: 80%;
        max-height: 80%;
        cursor: pointer;
    }
    svg {
        cursor: pointer;
    }
    .close {
        @apply absolute top-8 right-16;
    }
}

.clock {
    @apply absolute;
    height: 375px;
    width: 300px;
    z-index: 999;
    top: 30vh;
    left: 80px;
    .clockInner {
        height: 250px;
    }
    .timeDisplay {
        @apply text-center text-2xl;
    }
    .center {
        @apply absolute;
        height: 30px;
        width: 30px;
        border-radius: 100px;
        top: 135px;
        left: 50%;
        transform: (translateX(-50%));
        z-index: 25;
    }
    .hand {
        position: absolute;
        height: 150px;
        width: 1px;
        top: 75px;
        left: 50%;
        transform: translateX(-50%);
        transition: none;
    }
    .second::before {
        height: 100px;
        margin-top: -15px;
        display: block;
        content: '';
    }
    .minute::before {
        height: 130px;
        width: 8px;
        margin-top: -20px;
        display: block;
        content: '';
        transform: translateX(-2px);
    }
    .hour::before {
        height: 100px;
        margin-top: 5px;
        width: 11px;
        display: block;
        content: '';
        transform: translateX(-4px);
    }
    .inner {
        .mark {
            height: 200px;
            width: 1px;
            position: absolute;
            top: 50px;
            left: 138px;
            // border-left: 1px solid $secondary;
            &::before {
                @apply absolute top-0 border w-3 h-3;
                content: '';
                border-radius: 50px;
                left: -5px;
            }
            &:nth-of-type(3n + 3)::before {
                @apply h-3.5 w-3.5;
                left: -5px;
            }
            &:nth-of-type(1) {
                transform: rotate(30deg);
                &::before {
                    transform: rotate(-30deg);
                }
            }
            &:nth-of-type(2) {
                transform: rotate(60deg);
                &::before {
                    transform: rotate(-60deg);
                }
            }
            &:nth-of-type(3) {
                transform: rotate(90deg);
                &::before {
                    transform: rotate(-90deg);
                }
            }
            &:nth-of-type(4) {
                transform: rotate(120deg);
                &::before {
                    transform: rotate(-120deg);
                }
            }
            &:nth-of-type(5) {
                transform: rotate(150deg);
                &::before {
                    transform: rotate(-150deg);
                }
            }
            &:nth-of-type(6) {
                transform: rotate(180deg);
                &::before {
                    transform: rotate(-180deg);
                }
            }
            &:nth-of-type(7) {
                transform: rotate(210deg);
                &::before {
                    transform: rotate(-210deg);
                }
            }
            &:nth-of-type(8) {
                transform: rotate(240deg);
                &::before {
                    transform: rotate(-240deg);
                }
            }
            &:nth-of-type(9) {
                transform: rotate(270deg);
                &::before {
                    transform: rotate(-270deg);
                }
            }
            &:nth-of-type(10) {
                transform: rotate(300deg);
                &::before {
                    transform: rotate(-300deg);
                }
            }
            &:nth-of-type(11) {
                transform: rotate(330deg);
                &::before {
                    transform: rotate(-330deg);
                }
            }
        }
    }
}

.calc {
    @apply absolute;
    z-index: 160;
    height: 450px;
    width: 350px;
    top: 250px;
    left: 400px;
    .calcInner {
        @apply flex flex-col;
        height: calc(100% - 36px);
    }
    .screen {
        @apply w-10/12 h-10 py-1 px-2 flex justify-end items-center text-2xl border mx-auto my-2;
    }
    button {
        @apply flex justify-center items-center text-center text-base rounded-lg my-1 mx-0 border;
    }
    .buttons {
        @apply w-full grid grid-cols-4 grid-rows-5 gap-2 p-3 grow;
    }
    .number {
        @apply w-full h-full;
        &.clear {
            grid-column: 1/3;
            grid-row: 1/2;
        }
        &.zero {
            grid-column: 1/3;
            grid-row: 5/6;
        }
    }
    .operand {
        @apply w-full h-full;
        grid-column: 4/4;
        &.divide {
            grid-row: 1/2;
        }
        &.times {
            grid-row: 2/3;
        }
        &.minus {
            grid-row: 3/4;
        }
        &.add {
            grid-row: 4/5;
        }
        &.equal {
            grid-row: 5/6;
        }
    }
}

.spinnerWrap {
    @apply flex h-full w-full justify-center items-center;
}

.spinner {
    @apply h-14 w-14 border-8;
    border-radius: 100%;
    animation: rotation 2s infinite;
    &.small {
        height: 25px;
        width: 25px;
        border-width: 7px;
    }

    &.large {
        height: 100px;
        width: 100px;
    }
}

footer {
    @apply flex flex-col justify-center items-center w-full pb-16 mt-8 gap-8;
    .icons {
        @apply flex gap-24;
    }
    .links {
        @apply flex gap-8 text-xl uppercase;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@media (max-height: 855px) {
    .home {
        .logoBlock {
            @apply right-4 bottom-24;
            top: unset;
            left: unset;
        }
        .desktopIcons {
            @apply flex w-full h-fit justify-between px-8;
        }
        .about {
            @apply left-4 bottom-28;
            top: 50%;
            width: calc(100% - 450px - 2.5rem);
            height: unset;
        }
        .portfolio {
            height: calc(50% - 2.75rem);
        }
        .blog {
            right: 2rem;
            width: 40%;
            height: unset;
            bottom: calc(7rem + 180px);
        }
    }

    .window {
        .inner {
            min-height: unset;
        }
    }
}

@media (max-width: 1395px) {
    .home {
        .about {
            .techWrap {
                .item {
                    height: 28%;
                }
            }
        }
    }
}

@media (max-width: 1230px) {
    .home {
        .about {
            .techWrap {
                @apply p-0;
            }
        }
    }
}

@media (max-width: 1170px) {
    .home {
        .desktopIcons {
            .gitHub {
                grid-row: 3/4;
            }
        }
    }
    .page {
        .projects,
        .posts,
        &.about {
            @apply w-full;
        }
        &.about {
            .main {
                @apply w-full;
            }
        }
    }
}

@media (max-width: 901px) {
    .home {
        @apply grid p-5 gap-8;
        margin-top: 50px;
        .draggable,
        .desktopIcons {
            @apply relative h-fit w-full;
        }
        .switch {
            @apply fixed right-5;
        }
        .portfolio {
            @apply left-0 top-0 w-full h-fit;
        }
        .logoBlock {
            @apply relative mx-auto;
            grid-row: 2/3;
        }
        .blog {
            .inner {
                height: 300px;
            }
        }
        .about {
            .techWrap {
                @apply p-5;
                .item {
                    height: 70px;
                }
            }
        }
        .blog,
        .about,
        .logoBlock,
        .portfolio {
            right: unset;
            top: unset;
            left: unset;
            bottom: unset;
        }
        .desktopIcons {
            @apply flex flex-wrap mt-8 gap-8 justify-center mb-24;
            .icon {
                @apply w-1/12;
            }
            .clockIcon,
            .calcIcon {
                display: none;
            }
            .socialIcon {
                @apply mb-8 w-1/5;
            }
        }
    }
    .page {
        h1 {
            @apply w-full ml-0 mb-8 text-center;
        }
        .window {
            @apply mt-4 w-full;
            &:first-of-type {
                @apply mt-0;
            }
            &:nth-of-type(2n + 1),
            &:nth-of-type(2n) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
    .about {
        .portrait {
            margin-right: 2rem !important;
            margin-left: -2rem !important;
        }
    }
    .projects {
        @apply grid grid-cols-2 gap-6;
        max-width: 100%;
        .window {
            @apply w-full mt-0;
            max-width: 100%;
        }
    }
    .contact {
        @apply block mb-16;
        height: auto;
        .main {
            form {
                label {
                    @apply w-full;
                }
                .message {
                    @apply mb-3;
                }
            }
        }
    }
}
@media (max-width: 685px) {
    header {
        .siteName {
            display: none;
        }
    }
    .about {
        .portrait {
            float: none;
        }
    }
    footer {
        .icons,
        .links {
            @apply gap-3 justify-between w-full px-5 flex-wrap;
        }
    }
}

@media (max-width: 540px) {
    header {
        z-index: 999999;
        .mobile {
            @apply block;
            &:hover {
                @apply text-accent;
                cursor: pointer;
            }
        }
        nav {
            @apply absolute flex-col border left-3 gap-0 w-fit border-t-0 hidden;
            top: 57px;
            z-index: 999999;
            a {
                @apply py-4 px-16;
            }
            &.show {
                @apply flex;
            }
        }
    }
    body.bright {
        header {
            nav {
                @apply bg-base-100 border-neutral;
            }
        }
    }
    body.dark {
        header {
            @apply text-primary;
            nav {
                @apply bg-neutral border-primary;
            }
        }
    }
}

@media (max-width: 505px) {
    #container {
        &.pageContain {
            height: calc(100vh - 58px);
            margin-top: 58px;
        }
    }
    .home {
        .logoBlock {
            @apply text-center;
            width: 100%;
            grid-row: 1/2;
        }
        .blog {
            display: none;
        }
        .desktopIcons {
            .icon {
                width: auto;
            }
            .socialIcon {
                width: 18%;
            }
        }
    }
    .projects {
        @apply block;
        .window {
            @apply mb-5;
        }
    }
    .project {
        .window.main {
            .body {
                @apply flex-col-reverse;
            }
            .tech {
                margin-bottom: 0;
                margin-right: 0;
                width: 100%;
            }
        }
    }
    footer {
        margin-bottom: 5rem;
        .icons,
        .links {
            @apply justify-center gap-5;
            svg {
                height: 40px;
                width: auto;
            }
        }
    }
}

@media only screen and (-webkit-device-pixel-ratio: 2) {
    footer {
        margin-bottom: 5rem;
    }
}

@media only screen and (-webkit-device-pixel-ratio: 3) {
    footer {
        margin-bottom: 5rem;
    }
}
