$primary: #ff1493;
$secondary: #00ffff;
$accent: #a300ef;
$neutral: #100114;
$light: #f2ffff;

body.dark {
    @apply bg-neutral text-base-100;
    background-image: linear-gradient(
        165deg,
        $neutral 50%,
        rgba(140, 5, 77, 0.4)
    );
    * {
        ::-webkit-scrollbar-track {
            @apply border-primary;
        }

        ::-webkit-scrollbar-thumb {
            @apply bg-neutral block relative;

            border-color: #008989;
        }
        ::-webkit-scrollbar-corner {
            border-color: $primary;
        }
        ::-webkit-scrollbar-button {
            $size: 35%;
            $border: calc($size + 1px);
            &:vertical {
                &:increment,
                &:decrement {
                    border-color: $primary;
                }
                &:increment {
                    $vert: 180deg;
                    $left: 60deg;
                    $right: 300deg;
                    background-image: linear-gradient(
                            $left,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $right,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $left,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $right,
                            $primary $border,
                            transparent $size
                        );
                }
                &:decrement {
                    $vert: 0deg;
                    $left: 120deg;
                    $right: 240deg;
                    background-image: linear-gradient(
                            $left,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $right,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $left,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $right,
                            $primary $border,
                            transparent $size
                        );
                }
            }
            &:horizontal {
                &:increment,
                &:decrement {
                    border-color: $primary;
                }
                &:increment {
                    $vert: 180deg;
                    $left: 60deg;
                    $right: 300deg;
                    background-image: linear-gradient(
                            $left,
                            $light $size,
                            transparent $size
                        ),
                        linear-gradient($vert, $light $size, transparent $size),
                        linear-gradient($right, $light $size, transparent $size),
                        linear-gradient(
                            $left,
                            $neutral $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $neutral $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $right,
                            $neutral $border,
                            transparent $size
                        );
                }
                &:decrement {
                    $vert: 0deg;
                    $left: 120deg;
                    $right: 240deg;
                    background-image: linear-gradient(
                            $left,
                            $light $size,
                            transparent $size
                        ),
                        linear-gradient($vert, $light $size, transparent $size),
                        linear-gradient($right, $light $size, transparent $size),
                        linear-gradient(
                            $left,
                            $neutral $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $neutral $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $right,
                            $neutral $border,
                            transparent $size
                        );
                }
            }
            &:horizontal {
                &:increment,
                &:decrement {
                    border-color: $neutral;
                }
                &:increment {
                    $vert: 90deg;
                    $top: 210deg;
                    $bottom: 330deg;
                    background-image: linear-gradient(
                            $top,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $bottom,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $top,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $bottom,
                            $primary $border,
                            transparent $size
                        );
                }
                &:decrement {
                    $vert: 270deg;
                    $top: 140deg;
                    $bottom: 30deg;
                    background-image: linear-gradient(
                            $top,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $bottom,
                            $neutral $size,
                            transparent $size
                        ),
                        linear-gradient(
                            $top,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $vert,
                            $primary $border,
                            transparent $size
                        ),
                        linear-gradient(
                            $bottom,
                            $primary $border,
                            transparent $size
                        );
                }
            }
        }
    }
    a {
        @apply text-secondary hover:text-accent;
    }
    ul {
        li {
            &::before {
                @apply border-primary bg-primary/40;
            }
        }
    }
    .switch {
        .switchHandle {
            @apply bg-neutral border border-primary left-0;
            &::after {
                @apply absolute block border border-primary;
                content: '';
                width: 9px;
                height: 9px;
                left: 5px;
                top: 5px;
            }
        }
        .switchBack {
            @apply border border-primary;
        }
    }
    header {
        @apply border-primary bg-primary/5;
        nav {
            .active {
                @apply text-primary;
            }
        }
    }

    .page {
        h1 {
            @apply border-primary bg-neutral text-secondary;
            box-shadow: 3px 3px $neutral, 7px 10px $primary;
        }
        .window {
            h2 {
                @apply border-primary bg-primary/10;
            }
            .featImg {
                @apply border-primary;
            }
            .postFoot {
                @apply border-primary;
            }
        }
    }
    .post {
        .date,
        .categories {
            @apply bg-neutral border-primary;
        }
        .postWrap {
            @apply border-primary bg-neutral text-secondary;
        }
        figure {
            @apply border-primary;
            box-shadow: 4px 8px $neutral, 5px 7px $primary, 3px 9px $primary;
        }
    }

    .home {
        .logoBlock {
            .logoInner {
                @apply border border-primary bg-neutral text-secondary;
                box-shadow: 3px 3px $neutral, 7px 10px $primary;
                hr {
                    @apply border border-primary;
                }
            }
        }
        .switch {
            .switchHandle {
                &::after {
                    width: 19px;
                    height: 19px;
                    left: 9px;
                    top: 9px;
                }
            }
            .switchBack {
                @apply border border-primary;
            }
        }
        .blog {
            .post {
                @apply border-accent/40;
                .date {
                    @apply text-secondary;
                }
            }
        }
        .about {
            .techWrap {
                .hoverBox {
                    @apply bg-neutral border-primary;
                    &::after {
                        @apply bg-neutral border-primary;
                    }
                }
            }
        }
    }
    .window {
        @apply bg-neutral border border-primary;
        .bar {
            // background: repeating-linear-gradient(transparent, transparent 4px, $primary 4px, $primary 5px);
            $start: 7px;
            $line: 1px;
            $gap: 5px;
            $chunk: $line + $gap;
            background: linear-gradient(
                transparent,
                transparent $start,
                $primary $start,
                $primary $start + $line,
                transparent $start + $line,
                transparent $start + $chunk,
                $primary $start + $chunk,
                $primary $start + $chunk + $line,
                transparent $start + $chunk + $line,
                transparent $start + ($chunk * 2),
                $primary $start + ($chunk * 2),
                $primary $start + ($chunk * 2) + $line,
                transparent $start + ($chunk * 2) + $line,
                transparent $start + ($chunk * 3),
                $primary $start + ($chunk * 3),
                $primary $start + ($chunk * 3) + $line,
                transparent $start + ($chunk * 3) + $line
            );
            background-position: center center;
            background-repeat: no-repeat;
            .title {
                @apply px-6 bg-neutral items-center flex border-x border-primary;
                height: 33px;
                p,
                a {
                    @apply m-0;
                }
            }
        }
        .menuBar {
            @apply bg-neutral border-b border-primary;
        }
        .inner {
            @apply text-primary bg-neutral border border-primary;
        }
        .corner {
            @apply border border-primary;
        }
        .icon {
            @apply border-2 border-primary;
        }
        .tags {
            a.tag {
                @apply btn-neutral border-primary text-primary bg-primary/10;
                &:hover {
                    @apply btn-neutral border-secondary text-secondary bg-secondary/10;
                }
            }
        }
    }
    .bar {
        @apply bg-neutral border-b border-primary;
        a {
            @apply text-primary;
        }
        .barButton {
            @apply bg-neutral border-r border-primary;
            .dash {
                @apply bg-primary;
            }
        }
        .arrowBtn {
            @apply bg-neutral border-l border-primary;
        }
    }
    .desktopIcons {
        @apply text-secondary;
        .icon svg path {
            fill: $primary;
        }
    }
    .clock {
        @apply text-primary;
        .inner {
            .mark {
                &::before {
                    @apply border-primary;
                }
                &:nth-of-type(3n + 3)::before {
                    // @apply bg-primary;
                }
            }
            .center {
                @apply bg-neutral border border-primary;
            }
            .second::before {
                @apply border-l-2 border-primary;
            }
            .minute::before {
                @apply border border-secondary bg-neutral;
            }
            .hour::before {
                @apply border-2 border-secondary bg-neutral;
            }
        }
        .timeDisplay {
            @apply text-primary;
        }
    }

    .calc {
        .screen {
            @apply border-primary bg-primary/10;
        }
        button {
            @apply bg-neutral hover:bg-secondary/15 hover:text-base-100 active:bg-primary/15 active:text-base-100;
        }
        .number {
            @apply border-secondary text-secondary;
        }
        .operand {
            @apply border-primary text-primary;
        }
    }
    .project {
        .gallery {
            img {
                @apply border-primary;
            }
            .innerWrap {
                @apply bg-primary/10;
            }
        }
        .tech {
            .repo {
                @apply bg-primary/10 border-primary;
            }
        }
    }
    .spinner {
        @apply border-accent border-r-accent/25;
    }
    .bigImg {
        @apply bg-neutral/50 text-secondary;
        svg {
            @apply hover:text-accent;
            filter: drop-shadow(2px 2px $accent);
        }
        img {
            @apply border border-primary;
        }
    }
    .contact {
        .main {
            form {
                label {
                    @apply border-primary;
                }
                button {
                    @apply btn-neutral hover:btn-primary border-primary;
                    border-radius: 0;
                }
            }
        }
    }
}
