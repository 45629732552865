$primary: #ff1493;
$secondary: #00ffff;
$accent: #a300ef;
$neutral: #100114;
$light: #f2ffff;

body.bright {
    @apply bg-base-100 text-neutral;
    $shadow: 7px 7px $primary, 7px 7px 0 1px $neutral;
    $shadowPurple: 7px 7px $accent, 7px 7px 0 1px $neutral;
    $bgSize: 35px;
    background-image: radial-gradient($neutral 1px, transparent 0),
        linear-gradient(165deg, $light 50%, rgba($secondary, 0.24));
    background-size: $bgSize $bgSize, 100% 100%;

    ::-webkit-scrollbar-track {
        @apply border-neutral;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-secondary border-neutral;
        // outline-color: $neutral;
    }
    ::-webkit-scrollbar-corner {
        border-radius: 0 0 6px 0;
    }
    ::-webkit-scrollbar-button {
        $size: 35%;
        $border: calc($size + 1px);
        @apply bg-secondary;
        &:vertical {
            &:increment,
            &:decrement {
                border-color: $neutral;
            }
            &:increment {
                $vert: 180deg;
                $left: 60deg;
                $right: 300deg;
                background-image: linear-gradient(
                        $left,
                        $light $size,
                        transparent $size
                    ),
                    linear-gradient($vert, $light $size, transparent $size),
                    linear-gradient($right, $light $size, transparent $size),
                    linear-gradient($left, $neutral $border, transparent $size),
                    linear-gradient($vert, $neutral $border, transparent $size),
                    linear-gradient($right, $neutral $border, transparent $size);
            }
            &:decrement {
                $vert: 0deg;
                $left: 120deg;
                $right: 240deg;
                background-image: linear-gradient(
                        $left,
                        $light $size,
                        transparent $size
                    ),
                    linear-gradient($vert, $light $size, transparent $size),
                    linear-gradient($right, $light $size, transparent $size),
                    linear-gradient($left, $neutral $border, transparent $size),
                    linear-gradient($vert, $neutral $border, transparent $size),
                    linear-gradient($right, $neutral $border, transparent $size);
            }
        }
        &:horizontal {
            &:increment,
            &:decrement {
                border-color: $neutral;
            }
            &:increment {
                $vert: 90deg;
                $top: 210deg;
                $bottom: 330deg;
                background-image: linear-gradient(
                        $top,
                        $light $size,
                        transparent $size
                    ),
                    linear-gradient($vert, $light $size, transparent $size),
                    linear-gradient($bottom, $light $size, transparent $size),
                    linear-gradient($top, $neutral $border, transparent $size),
                    linear-gradient($vert, $neutral $border, transparent $size),
                    linear-gradient(
                        $bottom,
                        $neutral $border,
                        transparent $size
                    );
            }
            &:decrement {
                $vert: 270deg;
                $top: 140deg;
                $bottom: 30deg;
                background-image: linear-gradient(
                        $top,
                        $light $size,
                        transparent $size
                    ),
                    linear-gradient($vert, $light $size, transparent $size),
                    linear-gradient($bottom, $light $size, transparent $size),
                    linear-gradient($top, $neutral $border, transparent $size),
                    linear-gradient($vert, $neutral $border, transparent $size),
                    linear-gradient(
                        $bottom,
                        $neutral $border,
                        transparent $size
                    );
            }
        }
    }

    a {
        @apply text-primary hover:text-accent;
    }

    ul {
        li {
            &::before {
                @apply border-neutral bg-secondary/25;
            }
        }
    }

    .switch {
        .switchHandle {
            @apply bg-secondary border border-neutral;
            border-radius: 100%;
            left: 15px;
            &::after {
                display: none;
            }
        }
        .switchBack {
            @apply border border-neutral bg-base-100;
            border-radius: 50px;
            box-shadow: inset 3px 3px $primary, inset 3px 3px 0 1px $neutral;
        }
    }

    header {
        @apply border-neutral;
        nav {
            .active {
                @apply text-accent;
            }
        }
        // nav {
        //     text-shadow: 0 0 10px $light, 0 0 5px $light, 0 0 15px $light,
        //         0 0 20px $light, 0 0 25px $light;
        // }
    }

    .page {
        h1 {
            @apply bg-base-100 border-neutral rounded-lg;
            box-shadow: $shadow;
        }
        .window {
            h2 {
                @apply border-neutral bg-white;
            }
            .featImg {
                @apply border-neutral;
            }
            .postFoot {
                @apply border-neutral;
            }
        }
    }

    .post {
        .date,
        .categories {
            @apply border-neutral bg-base-100 rounded-t-lg;
        }
        .postWrap {
            @apply bg-base-100 border-neutral rounded-lg;
            box-shadow: $shadowPurple;
        }
        figure {
            @apply border-neutral rounded-lg overflow-hidden;
            box-shadow: $shadow;
        }
        .window {
            figure {
                &.alignfull {
                    border-radius: 0;
                    box-shadow: none;
                }
                &.alignwide {
                    margin-left: calc((3rem + 4px) * -1);
                }
            }
        }
    }

    .home {
        .logoBlock {
            .logoInner {
                @apply bg-base-100 border border-neutral rounded-lg;
                box-shadow: $shadow;
                hr {
                    @apply border border-primary;
                }
            }
        }
        .switch {
            .switchHandle {
                left: 30px;
            }
        }
        .portfolio {
            .icon {
                @apply rounded-md border border-neutral overflow-hidden;
                box-shadow: 4px 4px $primary, 4px 4px 0 1px $neutral;
                &:hover {
                    box-shadow: 2px 2px $primary, 2px 2px 0 1px $neutral;
                }
            }
        }
        .desktopIcons {
            .icon a {
                @apply text-neutral;
            }
        }
        .about {
            .techWrap {
                .hoverBox {
                    @apply bg-base-100 border-neutral rounded-md;
                    &::after {
                        @apply bg-base-100 border-neutral;
                    }
                }
            }
        }
    }
    .window {
        @apply bg-base-100 border border-neutral rounded-lg;
        box-shadow: $shadow;
        .cornerWrap {
            @apply rounded-lg;
        }
        .menuBar {
            @apply bg-white border-b border-neutral;
        }
        .inner {
            @apply bg-base-100 rounded-md;
        }
        .menuBar {
            @apply bg-base-100;
        }
        .bar {
            @apply bg-secondary overflow-hidden border-b border-neutral;
            border-radius: 6px 6px 0 0;
            a {
                @apply text-neutral;
            }
            .barButton {
                @apply bg-base-100 border-r border-neutral;
                .dash {
                    @apply bg-primary;
                }
            }
            .arrowBtn {
                @apply bg-base-100 border-l border-neutral;
            }
        }
        .corner {
            @apply border border-neutral;
        }

        .tags {
            a.tag {
                @apply btn-outline bg-secondary/15 hover:bg-secondary/50 hover:text-neutral;
            }
        }
    }

    .clock {
        .inner {
            @apply bg-base-100;
            .mark {
                &::before {
                    @apply border-primary;
                }
                &:nth-of-type(3n + 3)::before {
                    @apply bg-primary;
                }
            }
        }
        .center {
            @apply bg-base-100 border border-neutral;
        }
        .second::before {
            @apply border border-neutral;
        }
        .minute::before,
        .hour::before {
            @apply border border-primary bg-base-100;
        }
    }
    .calc {
        .screen {
            @apply bg-secondary/25 border-neutral rounded-md text-neutral;
        }
        button {
            @apply border-neutral hover:bg-primary/15 active:bg-secondary/20;
        }
        .operand {
            @apply border-primary text-primary;
        }
    }
    .project {
        .gallery {
            img {
                @apply rounded-md border-neutral;
                box-shadow: 4px 4px $primary, 4px 4px 0 1px $neutral;
                &:hover {
                    cursor: pointer;
                    box-shadow: 2px 2px $primary, 2px 2px 0 1px $neutral;
                }
            }
            .innerWrap {
                @apply bg-secondary/15;
            }
        }
        .body {
            .content {
                h2 {
                    @apply bg-transparent;
                }
            }
        }
        .tech {
            .repo {
                @apply bg-secondary/25 border-neutral;
            }
        }
    }
    .spinner {
        @apply border-secondary border-r-secondary/25;
    }
    .bigImg {
        @apply bg-accent/50 text-secondary;
        svg {
            @apply hover:text-accent;
            filter: drop-shadow(2px 2px $neutral);
        }
        img {
            @apply rounded-lg border border-neutral;
        }
    }
    .contact {
        .main {
            form {
                label {
                    @apply border-neutral;
                }
                button {
                    @apply btn-primary text-white hover:btn-accent border-neutral;
                    box-shadow: 4px 4px $secondary, 4px 4px 0 1px $neutral;
                    &:hover {
                        box-shadow: 2px 2px $secondary, 2px 2px 0 1px $neutral;
                    }
                }
            }
        }
    }
}

@media (max-width: 901px) {
    body.bright {
        .page {
            &.project {
                .window {
                    box-shadow: none;
                }
            }
        }
    }
}
